<template>
  <main class="SellersVips">
    <TheSubHeader 
      icon="medal" 
      type="filter"
      title="Sellers Vips"
      page-title-tag="Sellers Vips"
      organizer-gap="1rem"
      organizer-columns="1fr 200px"
      :actions="['back']"
    >
      <template #afterButtons>
        <AsyncExportButton
          v-if="permissions && permissions.sellersVips && permissions.sellersVips.exportSellersVipsButton"
          name="sellers-vips"
          name-report="Sellers-Vips"
          color="dark"
          label="Baixar planilha atual"
          label-loading="Gerando planilha"
          :track="trackSubheaderButtonString('Sellers Vips', 'Export')"
        />
      </template>
      <template #filter>
        <InputFile
          ref="inputFile"
          v-model="file"
          label="Importar nova Planilha"
          btn-label="Escolha o arquivo"
          accept-files=".xlsx, .xls, .zip, .xml"
        />
        <BaseButton
          label="Importar arquivo"
          theme="icon-left"
          filled
          color="primary-base"
          text-color="white"
          icon="download"
          :disabled="file === null"
          @click="sellersVipsImport"
        />
      </template>
    </TheSubHeader>
    <div class="SellersVips__content">
      <h1 class="SellersVips__title">
        HISTÓRICO DE IMPORTAÇÕES
      </h1>
      <div class="SellersVips__table">
        <DataTable
          :header="sellersVipsImportTable.data.headers"
          :table="sellersVipsImportTableWithLoading"
          is-sticky
          @see="errorsModal"
          @download="downloadSellerVipFile"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
    <DialogErrors
      :modal-error="sellersVipsImportErrors"
      :show-errors-modal="showErrors"
      @closeModal="showErrors = false"
    />
  </main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { DataTable, InputFile, Pagination, AsyncExportButton } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import  DialogErrors from '@/views/sellers/Vips/DialogErrors'
import { sellers } from '@/api'

export default {
  name: 'Sellers',
  components: {
    TheSubHeader,
    DataTable,
    Pagination,
    BaseButton,
    InputFile,
    DialogErrors,
    AsyncExportButton
  },
  
  data() {
    return {
      file: null,
      showErrors: false,
      updating: false,
      time: null,
      timeOutUpdate: 10 * 1000,
    }
  },
  
  computed:{
    ...mapState({
      sellersVipsImportTable: state => state.sellers.sellersVipsImportTable,
      sellersVipsImportErrors: state => state.sellers.sellersVipsImportErrors,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions
    }),
    
    ...mapGetters({
      sellersVipsImportTableWithLoading: 'sellers/sellersVipsImportTableWithLoading'
    }),
  },

  mounted(){
    this.search()
  },

  methods:{
    ...mapActions({
      fetchSellersVipsImportTable: 'sellers/fetchSellersVipsImportTable',
      fetchSellersVipsImportErrors: 'sellers/fetchSellersVipsImportErrors',
      importSellersVips: 'sellers/importSellersVips',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading'
    }),

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    resetFile(){
      this.file = null
      this.$refs.inputFile.reset()
    },

    search(loading = true){

      if (loading) {
        this.setLoading(true)
      }

      const params = {
        paginate: this.paginate,
        page: this.page
      }
      this.fetchSellersVipsImportTable(params)
        .then(() =>{
          this.setLoading(false)
          this.setPagination(this.sellersVipsImportTable)
          this.checkSellersVipsImportTable()
        })
    },

    checkSellersVipsImportTable() {
      if (
        JSON.stringify(this.sellersVipsImportTableWithLoading).indexOf('Andamento') > 0 && 
        this.$route.name === 'sellersVips'
      ) {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.search(false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(this.sellersVipsImportTable)
        }
      } else {
        clearTimeout(this.time)
        this.setPagination(this.sellersVipsImportTable)
      }
    },

    async sellersVipsImport() {
      let formData = new FormData()
      formData.append('file', this?.file)
      
      const res = await this.importSellersVips(formData)
      this.resetFile()
      
      if(!res.trace) {
        this.resetSearch()
      }
    },

    async downloadSellerVipFile(data) {
      this.setLoading(true)
      await sellers.downloadSellerVipFile(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    //Errors
    async errorsModal(data){
      await this.fetchSellersVipsImportErrors(data)
      this.showErrors = true
    },
    closeErrorList(){
      this.showErrors = false
    },
  }
}
</script>

<style lang="scss" scoped>
.SellersVips {
  &__content {
    display: flex;
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-style: normal;
    font-size: $font-size-mini;
    color: $color-neutral-stronger;
    margin-bottom: 20px;
  }

  &__table {
    margin-bottom: 50px;
  }
}
</style>