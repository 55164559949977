<template>
  <div class="DialogErrorsHistory">
    <!-- erros modal -->
    <Modal
      v-if="showErrorsModal"
      @close="$emit('closeModal', 'showErrorsModal')"
    >
      <template #header>
        <div class="DialogErrorsHistory__modalHeader">
          Erros no arquivo: <strong>{{ modalError.file_name }}</strong>
        </div>
      </template>

      <template #body>
        <DataTable
          :header="modalError.headers"
          :table="modalError.content"
          :max-size="false"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { Modal } from '@/components/molecules'
import { DataTable } from '@/components/molecules'

export default {
  name: 'DialogErrors',

  components: {
    Modal,
    DataTable,
  },

  props: {
    modalError: {
      type: Object,
      require: true,
      default: () => {}
    },
    showErrorsModal: {
      type: Boolean,
      require: true,
      default: false
    },
  }, 
}
</script>

<style lang="scss">
  .DialogErrorsHistory {
    .DialogErrorsHistory__modalHeader {
      padding-top: 6px;
      padding-left: 10px;
    }
    
    .Modal__container {
      width: 600px;
      height: fit-content;
      max-height: calc(100vh - 40px);
      padding-bottom: 20px;
    }

    .Modal__buttons {
      display: flex;
      justify-content: space-evenly;
    }

    .Modal__button {
      width: 40% !important;
    }
  }
</style>
